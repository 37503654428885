import React from 'react';
import styled from 'styled-components';

// styles
import '../styles/index.css';
import '/node_modules/primeflex/primeflex.css';

// components
import MainLayout from '../hoc/MainLayout';
import { graphql } from 'gatsby';
import { loadCrispChat } from '../common/utils';
import Seo from '../components/shared/Seo';

const StyledContainer = styled.div`
  ul {
    list-style: none;
  }
`;

const UpdatesPage = ({ data }) => {
    loadCrispChat();
    const monthShortNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    function dateFormat(d) {
        const t = new Date(d);
        return monthShortNames[t.getMonth()] + ', ' + t.getFullYear();
    }

    return (
        <MainLayout>
            <Seo title='Lofi.co - Craft your focus environment' keywords={['music', 'focus', 'relax', 'work']} lang='en' />
            <main className='flex justify-content-center'>
                <div className='flex flex-column mb-8 w-8'>
                    <h2 className='text-4xl md:text-5xl font-bold mb-6'>
                        What&apos;s new
                    </h2>
                    <div className='flex flex-column'>
                        {data.allPrismicUpdates.edges.map(edge => {
                            const date = dateFormat(new Date(edge.node.data.date));
                            return (
                                <div key={edge.node.id} className='grid font-bold font-helvetica'>
                                    <p style={{ color: '#F2501D' }} className='col-12 md:col-4 text-lg'>{date}</p>
                                    <StyledContainer className='col-12 md:col-8' dangerouslySetInnerHTML={{ __html: edge.node.data.new[0].text.html }} />
                                    <hr className='my-5 col-12' style={{ color: '#B1B1B1', padding: '0' }} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </main>
        </MainLayout>
    );
};

export const query = graphql`
    query MyQuery {
      allPrismicUpdates(sort: {fields: data___date, order: DESC}) {
        edges {
          node {
            id
            data {
              date
              new {
                text {
                  html
                }
              }
            }
          }
        }
      }
    }
`;

export default UpdatesPage;